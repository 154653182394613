<template>
  <div :class="['snackbar-wrapper', theme.theme, color.color]">
    <!-- eslint-disable-next-line prettier/prettier -->
    <div :class="['snackbar-content-wrapper flex-inline', { centered: centered }]">
      <div :class="iconClass">
        <slot name="icon">
          <AlertFill />
        </slot>
      </div>
      <div v-if="title" class="title bold">
        {{ title }}
      </div>
      <a v-if="linkCheck" :href="link?.href" class="link bold uc">
        {{ link?.title }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon, Theme, Color } from '~/types/icons';
// import { themeColors } from '~/util/color';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: String as () => Icon,
    required: false,
    default: undefined,
  },
  theme: {
    type: Object as () => {
      theme: Theme;
    },
    default: () => ({
      theme: 'light',
    }),
  },
  color: {
    type: Object as () => {
      color: Color;
    },
    required: true,
  },
  link: {
    type: Object as () => {
      href: string;
      title: string;
    },
    required: false,
    default: undefined,
  },
  centered: {
    type: Boolean,
    default: false,
  },
});

// const state = reactive({
//   colors: themeColors,
//   isDark: props.theme?.theme === 'dark',
// });

const iconClass = computed(() => {
  const classes: any = {
    icon: true,
  };
  classes[`icon-${props.color?.color}`] = true;
  return classes;
});
const linkCheck = computed<boolean>(() => !!props.link && !!props.link?.title && !!props.link?.href);
</script>

<style lang="scss" scoped>
.snackbar-wrapper {
  display: flex;
  padding: #{local-functions.rem-calc(16 20)};
  width: 100%;
  border-radius: 4px;

  .link {
    margin-left: auto;
  }

  .icon {
    height: 24px;
    width: 24px;
    margin-right: 18px;
  }

  .snackbar-content-wrapper {
    height: 100%;

    &.centered {
      justify-content: center;
    }
  }
}

.snackbar-wrapper.light {
  &.secondary {
    color: $color-secondary-600;
    background: $color-secondary-50;
  }

  &.danger {
    color: $color-danger-dark;
    background: $color-danger-light;
  }

  &.success {
    color: $color-success-dark;
    background: $color-success-light;
  }

  &.warning {
    color: $color-warning-dark;
    background: $color-warning-light;
  }

  .icon-secondary {
    path {
      fill: $color-secondary-600;
    }
  }

  .icon-success {
    path {
      fill: $color-success-dark;
    }
  }

  .icon-warning {
    path {
      fill: $color-warning-dark;
    }
  }

  .icon-danger {
    path {
      fill: $color-danger-dark;
    }
  }
}

.snackbar-wrapper.dark {
  .title,
  .link {
    color: $color-neutral-white;
  }

  &.secondary {
    background: $color-secondary-600;
  }

  &.danger {
    background: $color-danger-dark;
  }

  &.success {
    background: $color-success-dark;
  }

  &.warning {
    background: $color-warning-dark;
  }

  :deep(svg) {
    path {
      fill: $color-neutral-white;
    }
  }
}

.title {
  font-size: 1.125rem;
  line-height: 25px;
}
</style>
